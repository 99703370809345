/*global window,document,theme,jQuery,WebFont,AOS,setInterval,clearInterval,console*/
/*eslint no-console: ["off"] */
window.theme = window.theme || {};

(function ($) {
    'use strict';

    // var $ = jQuery;

    theme.init = function () {
        AOS.init({
            easing: 'ease-out-quad',
            once: true,
            offset: 60,
            disableMutationObserver: true
        });

        WebFont.load({
            typekit: {
                id: 'jbs6rmm'
            }
        });

        theme.Menus = (function () {
            var $menuToggle = $('.menu-toggle'),
                $subMenuToggle = $('.menu li.menu-item-has-children > a');

            // Mobile menu toggle
            $menuToggle.click(function () {
                $('html').toggleClass('menu-open');
                $('nav').toggleClass('open');
            });

            // Sub-menu toggle
            $subMenuToggle.click(function () {
                // $otherSubMenuToggles = $subMenuToggle.not(this);
                $subMenuToggle.not($(this)).removeClass('open');
                $(this).toggleClass('open');
                $(this).next('.sub-menu').slideToggle(200);

                $('.menu li.menu-item-has-children .sub-menu').not($(this).next('.sub-menu')).slideUp(200);
            });
        }());


        // Prevent default on '#' anchors
        $('a[href="#"]').on('click', function (e) {
            e.preventDefault();
        });

        // Initialize Featherlight Gallery
        $('.gallery-item a').add($('.pages a')).featherlightGallery();
    };

    theme.Slideshow = function ($container) {
        var classes = {
            animateOut: 'animate-out'
        };

        theme.loadImageSection($container);
        $container
            .on('beforeChange', function (e, slick, currentSlide) {
                $(slick.$slides[currentSlide]).addClass(classes.animateOut);
            })
            .on('afterChange', function (e, slick) {
                $(slick.$slides).removeClass(classes.animateOut);
            });

        function checkBool(x) {
            if(x == 'true') {
                return true;
            } else if(x == 'false') {
                return false;
            }
        }

        function convNum(x) {
            var num = Number(x);
            return num;
        }

        $container.slick({
            accessibility: true,
            arrows: $container.attr('data-arrows')
                ? checkBool($container.attr('data-arrows'))
                : false,
            prevArrow: $container.attr('data-arrows')
                ? '<button type="button" class="slick-prev"><svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.3,10.5h9.8v-3H6.3l4.5-4.9l-2.2-2L1.8,8L0.9,9l0.9,1l6.9,7.5l2.2-2L6.3,10.5z" fill="currentColor"></path></svg></button>'
                : '<button type="button" class="slick-prev">Previous</button>',
            nextArrow: $container.attr('data-arrows')
                ? '<button type="button" class="slick-next"><svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.7 7.5H.8v3h9.8l-4.5 4.9 2.2 2 6.9-7.5.9-1-.9-1L8.3.5l-2.2 2 4.6 5z" fill="currentColor"></path></svg></button>'
                : '<button type="button" class="slick-next">Next</button>',
            centerMode: $container.attr('data-center')
            ? checkBool($container.attr('data-center'))
            : false,
            dots: $container.attr('data-dots')
                ? checkBool($container.attr('data-dots'))
                : false,
            fade: $container.attr('data-fade')
                ? checkBool($container.attr('data-fade'))
                : false,
            initialSlide: $container.attr('data-initial')
                ? convNum($container.attr('data-initial'))
                : 0,
            speed: $container.attr('data-speed')
                ? convNum($container.attr('data-speed'))
                : 500,
            slidesToShow: $container.attr('data-slide-count')
                ? convNum($container.attr('data-slide-count'))
                : 3,
            slidesToScroll: $container.attr('data-slide-count')
                ? convNum($container.attr('data-slide-count'))
                : 3,
            responsive: (function () {
                if( !$container.attr('data-slide-count') || convNum($container.attr('data-slide-count')) > 1 ) {
                    var responsive = [
                        {
                            breakpoint: 960,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3
                            }
                        },
                        {
                            breakpoint: 720,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 540,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            },
                        }
                    ];
                    return responsive;
                }
            }()),
            draggable: false,
            touchThreshold: 5,
            pauseOnHover: false,
            autoplay: $container.attr('data-autoplay')
                ? checkBool($container.attr('data-autoplay'))
                : true,
            autoplaySpeed: $container.attr('data-autoplayspeed')
                ? convNum($container.attr('data-autoplayspeed'))
                : 5000,
            infinite: false
        });
        // $container.slick({
        //     accessibility: true,
        //     arrows: true,
        //     dots: false,
        //     fade: false,
        //     speed: 500,
        //     slidesToShow: 4,
        //     slidesToScroll: 4,
        //     draggable: false,
        //     touchThreshold: 5,
        //     pauseOnHover: false,
        //     autoplay: true,
        //     autoplaySpeed: 5000,
        //     infinite: false
        // });

        if (AOS) {
            AOS.refresh();
        }
    };

    theme.loadImageSection = function ($container) {
        // Wait until images inside container have lazyloaded class
        function setAsLoaded() {
            $container.removeClass('loading loading--delayed').addClass('loaded');
        }

        function checkForLazyloadedImage() {
            return $container.find('.lazyloaded').length;
        }

        // If it has SVGs it's in the onboarding state so set as loaded
        if ($container.find('svg').length) {
            setAsLoaded();
            return;
        }

        if (checkForLazyloadedImage() > 0) {
            setAsLoaded();
            return;
        }

        var interval = setInterval(function () {
            if (checkForLazyloadedImage() > 0) {
                clearInterval(interval);
                setAsLoaded();
            }
        }, 80);
    };

    // theme.ajaxPageHandler = (function () {
    //     var $body = $('body'),
    //         $links = $('a'),
    //         ajaxLoad = function (html) {
    //             console.log('ajaxLoad');
    //             document.title = $(html).filter('title').text();
    //         },
    //         loadPage = function (href) {
    //             console.log('loadPage');
    //             $body.load(href + ' body>*', ajaxLoad);
    //         };

    //     $links.click(function () {
    //         var href = $(this).attr('href');

    //         if (href.indexOf(document.domain) > -1 || href.indexOf(':') === -1) {
    //             history.pushState(null, null, href);

    //             $body.fadeOut('slow', function () {
    //                 loadPage(href);
    //             });
    //             return false;
    //         }
    //     });
    // }());

    theme.mouseTracking = function ($container) {
        $container.each(function () {
            var $img = $(this).find('img'),
                offset = $(this).offset(),
                // input setup
                input = {
                    mouseX: {
                        start: offset.left,
                        end: offset.left + $(this).outerWidth(),
                        current: 0
                    },
                    mouseY: {
                        start: $(this).offset().top,
                        end: $(this).offset().top + $(this).outerHeight(),
                        current: 0
                    }
                },
                // output setup
                output = {
                    x: {
                        start: 40,
                        end: 60,
                        current: 0
                    },
                    y: {
                        start: 40,
                        end: 60,
                        current: 0
                    }
                };

            console.log($img);
            // setup ranges
            input.mouseX.range = input.mouseX.end - input.mouseX.start;
            input.mouseY.range = input.mouseY.end - input.mouseY.start;
            output.x.range = output.x.end - output.x.start;
            output.y.range = output.y.end - output.y.start;

            $container.mousemove(function (e) {
                input.mouseX.current = e.clientX;
                input.mouseX.fraction = (input.mouseX.current - input.mouseX.start) / input.mouseX.range;
                input.mouseY.current = $(window).scrollTop() + e.clientY;
                input.mouseY.fraction = (input.mouseY.current - input.mouseY.start) / input.mouseY.range;

                output.x.current = output.x.start + (input.mouseX.fraction * output.x.range);
                output.y.current = output.y.start + (input.mouseY.fraction * output.y.range);

                $img.css('object-position', '' + output.x.current + '% ' + output.y.current + '%');
            });

            console.log($(this).offset().top);
            console.log(input);
            // console.log(output);
        });
    };
    // $('section.banner .content-wrapper').mousemove(function (e) {
    //     var clientCoords = "( " + e.clientX + ", " + e.clientY + " )";
    //     console.log(clientCoords);
    // });

    $(document).ready(function () {
        theme.init();
        // theme.Slideshow($('.slide-list'));
        theme.Slideshow($('#hero .slide-list'));
        theme.Slideshow($('.card-slider .slide-list'));
        // theme.Slideshow($('section.projects .project-list .row'));
        theme.loadImageSection($('section.banner .content-wrapper'));
        theme.loadImageSection($('.page-hero'));
    });

    // $(window).load(function () {
    //     theme.mouseTracking($('section.banner .content-wrapper'));
    // });
}(jQuery));